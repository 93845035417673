/* eslint-disable class-methods-use-this */
import api from '../API/config';

class AuthService {
  async login(user) {
    const body = {
      email: user.email,
      password: user.password,
    };
    try {
      const { data } = await api.post(`${api.authURL}`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      if (error.response.data[0] === 'EMAIL_NOT_VERIFIED') {
        return 'EMAIL_NOT_VERIFIED';
      }
      return null;
    }
  }

  async confirmUserEmail(hashcod) {
    try {
      const { data } = await api.get(`user/register/confirm/${hashcod}/`);

      if (data && data.detail === 'Registered Successfully') {
        return 'VERIFIED_SUCCESSFULLY';
      }
      return null;
    } catch (error) {
      if (
        error.response?.data?.Error ===
        'You do not have permission to access this link.'
      ) {
        return 'INVALID_HASHCOD';
      }

      return null;
    }
  }
}

export default new AuthService();
